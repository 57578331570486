<script setup lang="ts">
import { AuthStep1, AuthStep2, AuthStep3 } from '~/components/Auth'

const emits = defineEmits<{
  'current-step': [step: string]
  'on-success-login': []
}>()
defineSlots<{ header(): any }>()

const componentsStep = reactive({
  Email: shallowRef(AuthStep1),
  Token: shallowRef(AuthStep2),
  SignUp: shallowRef(AuthStep3),
})

const currentStep = ref('Email')
const inputToken = ref('')
const inputEmail = ref({
  email: '',
  isEmailSignUp: false,
})

const handleNavigation = (navigation: string) => {
  currentStep.value = navigation
  emits('current-step', currentStep.value)
}

const handleRedirect = () => {
  emits('on-success-login')
}

const onSuccessEmail = (payload: {
  isEmailSignUp: boolean
  navigation: string
}) => {
  inputEmail.value.isEmailSignUp = payload.isEmailSignUp
  handleNavigation(payload.navigation)
}
</script>

<template>
  <div class="flex w-full flex-col tablet:mt-16">
    <transition name="fade" mode="out-in">
      <component
        :is="componentsStep[currentStep as keyof typeof componentsStep]"
        :input-email="inputEmail.email"
        :input-token="inputToken"
        :is-email-sign-up="inputEmail.isEmailSignUp"
        source-page="modal"
        @handle-redirect="handleRedirect"
        @on-go-back="handleNavigation"
        @on-success-code="handleNavigation"
        @on-success-email="onSuccessEmail"
        @on-update-token="inputToken = $event"
        @update:email="inputEmail.email = $event"
      >
        <template #step-1-before-form>
          <slot name="header">
            <div class="mb-1 text-md font-bold">
              {{ $t('auth.loginOrRegister') }}
            </div>
          </slot>
        </template>
      </component>
    </transition>
  </div>
</template>
